import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../components/common/Link"
import InstagramClient from "./InstagramClient"

export default function Instagram() {
  // const data = useStaticQuery(graphql`
  //   {
  //     allInstagramContent(
  //       filter: { username: { eq: "malmofhsk" } }
  //       limit: 20
  //     ) {
  //       nodes {
  //         permalink
  //         media_type

  //         localFile {
  //           publicURL
  //           childImageSharp {
  //             gatsbyImageData(aspectRatio: 1)
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  // const photos = data.allInstagramContent.nodes

  //filter photos with media_type === "IMAGE"
  // const photos = data.allInstagramContent.nodes
  // console.log(photos)
  return (
    <div className="px-4 md:px-24 bg-gray-200 overflow-hidden">
      <div className="pt-12 pb-20 dots">
        <div className="overflow-hidden">
          <div className="relative">
            <div className="flex flex-row justify-between">
              <p className="text-2xl py-4 font-medium">
                Följ oss på Instagram{" "}
                <Link to="https://www.instagram.com/malmofhsk/">
                  <span className="text-primary">@malmofhsk</span>
                </Link>
              </p>
            </div>

            <InstagramClient />
            {/* <div className="grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-4 w-full overflow-hidden">
              {photos.map(post => {
                return (
                  <div key={post.id}>
                    <Link to={post.permalink} title="Visa på Instagram">
                      <GatsbyImage
                        image={getImage(post.localFile.publicURL)}
                        alt="Instagram"
                      />
                    </Link>
                  </div>
                )
              })}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
