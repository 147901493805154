import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import Instagram from "../components/Instagram"

import IndexNews from "../components/aktuellt/IndexNews"
import IntroPitch from "../components/index/IntroPitch"
import Konferens from "../components/index/Konferens"

const IndexPage = () => (
  <Layout isHome={true}>
    <Seo title="Malmö Folkhögskola: Din folkhögskola i Skåne" isHome={true} />
    <div className="index">
      <div className="relative">
        <Hero />
      </div>
      <div>
        <IndexNews />
        <IntroPitch />

        <Instagram />
        <Konferens />
      </div>
    </div>
  </Layout>
)

export default IndexPage
