import React from "react"
import Link from "./common/Link"

export default function InstagramClient() {
  const [photos, setPhotos] = React.useState([])

  React.useEffect(() => {
    fetch(
      `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,username,timestamp&access_token=IGQWRNdGxFeWcxc2NuWUE4RnVUT3J0ZAldwVWp1bW9kclYta01Dc3hrTGZApQl81aUJqVnlSamNfS3VfVGZAra09CUkxWQkNyRGxVYkNKMi14TDQtZAGU4OUdFd044WEJmaG5pUUF6ZAU11VUhhdW11dURhNmdVM3lxR1kZD&limit=30`
    )
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(resultData => {
        console.log(resultData)
        // Filter out videos and carousels
        const photoPosts = resultData.data.filter(
          post => post.media_type === "IMAGE"
        )
        setPhotos(photoPosts)
      })
      .catch(error => {
        console.error("Error fetching Instagram data:", error)
        setPhotos([]) // Set the data to an empty array if the request fails
      })
  }, [])

  return (
    <div className="grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-4 w-full overflow-hidden">
      {photos.length > 0 && (
        <>
          {photos.slice(0, 10).map(post => {
            return (
              <div key={post.id} className="aspect-square">
                <Link to={post.permalink} title="Visa på Instagram">
                  <img
                    src={post.media_url}
                    alt="Instagram"
                    className="h-full w-full object-cover"
                  />
                </Link>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}
