import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Fade } from "react-reveal"
import polka from "../../images/polka.svg"
import Link from "../common/Link"
export default function IntroPitch() {
  return (
    <div className="bg-white   text-gray-800  md:min-h-screen flex flex-col md:items-center md:justify-center">
      <div className="px-4 md:px-24 py-16 space-y-16 ">
        <div className="flex flex-col md:flex-row md:gap-24  items-center">
          <div className="w-full md:w-1/2">
            <Fade cascade left>
              <div className="space-y-8">
                <h2 className=" md:leading-none ">
                  Folkhögskola i unik miljö med staden runt knuten
                </h2>
                <div className="md:text-3xl  font-normal text-gray-800">
                  <p className="leading-relaxed">
                    Malmö Folkhögskola är en av 18 folkhögskolor i Skåne. Vår
                    skola, en fin gammal bondgård med anor från slutet av
                    1800-talet, är belägen i Fosie, bara 15 minuters bilfärd
                    från Malmö centrum. Vi erbjuder fem olika profiler av allmän
                    kurs på gymnasienivå, särskilda kurser på distans eller
                    online, kvällskurser samt konferensverksamhet.
                  </p>
                  <p className="my-4">
                    <Link
                      to="/att-studera/om-skolan/"
                      className="display underline decoration-primary decoration-4 underline-offset-4"
                      title="Om skolan"
                    >
                      Mer om Malmö Folkhögskola
                    </Link>
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="w-full md:w-1/2 relative z-10">
            <StaticImage
              src="../../images/yard.jpg"
              className="shadow-xl z-20"
              alt="Yard"
            />
            <div className="hidden md:block w-1/2 h-1/2 md:absolute top-0 -mt-8  md:-mt-24 -ml-12">
              <img src={polka} alt="polka" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
