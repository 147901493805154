import React from "react"

import logo from "../images/logo-black.svg"
import LoggaMono from "./common/LoggaMono"
import Video from "./Video"
import config from "react-reveal/globals"

config({ ssrFadeout: true })
export default function Hero() {
  return (
    <div className="h-[75vh] relative bg-black">
      <div className="h-full w-full  overflow-hidden relative">
        <Video />
      </div>

      <div className="absolute  text-white  inset-0 ">
        <div className="flex  flex-row justify-center md:justify-start items-center md:items-start w-full h-full">
          <div className="md:p-8 flex flex-col ">
            <div className="text-4xl font-normal leading-normal  md:pt-12 md:-mt-12 text-right relative">
              <div className="w-56 md:w-96 fill-current text-black">
                <LoggaMono />
              </div>
            </div>
          </div>
          {/* <div className=" flex-col justify-end h-full">
            <nav className="m-16 border-t-8 pt-8 border-white">
              <Fade left cascade distance="200px">
                <ul className="nav-list display text-7xl leading-tight flex flex-col justify-end items-end">
                  <Link to="/utbildningar/allmanna-kurser">
                    <li className="cursor-pointer">Utbildningar</li>
                  </Link>
                  <Link>
                    <li className="cursor-pointer">Aktuellt</li>
                  </Link>
                  <Link>
                    <li className="cursor-pointer">Konferens</li>
                  </Link>
                  <Link>
                    <li className="inline-block  ">Ansök</li>
                  </Link>
                </ul>
              </Fade>
            </nav>
          </div> */}
        </div>
      </div>
      <div className="flex flex-row justify-center relative -top-24">
        {/* <div className="w-12 animate-bounce">
          <Arrow />
        </div> */}
      </div>
    </div>
  )
}
