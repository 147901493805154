import React from "react"
import NewsItem from "./NewsItem"
import { useStaticQuery, graphql } from "gatsby"

export default function IndexNews() {
  const data = useStaticQuery(graphql`
    {
      allWpPost(limit: 4, sort: { order: DESC, fields: date }) {
        nodes {
          uri
          title
          date(formatString: "YYYY.MM.DD")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          content
          direktLank {
            filDirektlank {
              mediaItemUrl
            }
            lankaDirektTillFil
          }
        }
      }
    }
  `)
  const news = data.allWpPost.nodes
  return (
    <>
      {news.length > 0 && (
        <div className="px-4 md:px-24 py-8 md:py-12 space-y-16 text-gray-900 orange-dots">
          <div className="md:space-y-8">
            <h2 className="">Aktuellt</h2>
            <div className="grid md:grid-cols-2 gap-4 md:gap-8 ">
              {news.map((post, i) => {
                return <NewsItem key={i} post={post} context="indexFeed" />
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
