import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Fade } from "react-reveal"
import Link from "../common/Link"
import polka2 from "../../images/polka2.svg"

export default function Konferens() {
  return (
    <div className=" pink-dots  text-gray-800 py-8 md:py-24   md:min-h-screen flex flex-col items-center justify-center">
      <div className="px-4 md:px-24 mx-auto space-y-16">
        <div className="flex flex-col md:flex-row gap-8 md:gap-32  items-center">
          <div className="w-full md:w-1/2 order-1">
            <Fade cascade right>
              <div className="md:space-y-8">
                <h2 className="leading-none ">
                  Konferens i stimulerande miljö
                </h2>
                <div className="md:text-3xl leading-relaxed font-normal text-gray-800">
                  <p className="leading-relaxed">
                    Är du på jakt efter trevliga konferenslokaler i Malmö? Vi
                    erbjuder moderna, trivsamma konferenslokaler för kurs- och
                    konferensverksamhet, som går att möblera efter olika
                    funktioner och önskemål. På vår inbjudande innergård finns
                    prunkande träd och blommor som under sommarhalvåret erbjuder
                    en skön, avkopplande miljö för fika och rast.
                  </p>
                  <p className="my-4">
                    <Link
                      to="/konferens"
                      className="display underline decoration-primary decoration-4 underline-offset-4"
                      title="Konferens"
                    >
                      Information om konferens
                    </Link>
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="w-full md:w-1/2 relative z-10">
            <StaticImage
              src="../../images/blossom.jpg"
              className="md:shadow-2xl z-20"
            />
            <div className="w-1/2 h-1/2 absolute hidden md:block bottom-0 right-0 -mb-16 -mr-12">
              <img src={polka2} alt="Illustration" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
